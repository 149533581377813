import React from 'react';
import './style.scss';

const Tag = ({size, children}) => {
  return (
    <div className={`tag tag__${size}`}>{children}</div>
  )
}

export default Tag;
